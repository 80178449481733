import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Success from "./pages/Success";
import Error from "./pages/Error";
import "./App.css"
export default function App() {
  return (
    <div className="overflow-x-hidden">
      <Routes>
        <Route path="/register" element={<Login />} />
        <Route path="/success" element={<Success />} />
        <Route path="/error" element={<Error />} />
      </Routes>
    </div>
  );  
}
